export const socialsData = {
    github: 'https://github.com/AshifAli007',
    // facebook: 'https://www.instagram.com/theashifkhan/',
    linkedIn: 'https://www.linkedin.com/in/mohd-ashif-052b71194/',
    // instagram: 'https://www.instagram.com/theashifkhan/',
    codepen: 'https://codepen.io/ashifali007',
    twitter: 'https://twitter.com/MohdAsh54173738',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/',
    leetcode: 'https://leetcode.com/Ashif_Khan/',
}