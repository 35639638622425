export const educationData = [
    {
        id: 1,
        institution: 'Florida State University',
        course: 'Master of Science in Computer Science',
        startYear: '2023',
        endYear: '2025'
    },
    {
        id: 2,
        institution: 'Univesity of Delhi',
        course: 'Bachelor of Science in Computer Science',
        startYear: '2021',
        endYear: '2018'
    }
]